// import { withSentry } from "@sentry/remix";
import type { LoaderFunction, MetaFunction } from "@remix-run/node";
import { json } from "@remix-run/node";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  isRouteErrorResponse,
  useLoaderData,
  useRouteError,
} from "@remix-run/react";
// @ts-ignore
import stylesheet from "./globals.css?url";
import { requireEnv } from "./entry.server";
import Error401 from "./components/401";
import Error404 from "./components/404";
import { isProd } from "conf/getEnv";
// import { withLDProvider } from "launchdarkly-react-client-sdk";

export function links() {
  return [
    { rel: "stylesheet", href: "https://unpkg.com/primeicons/primeicons.css" },
    {
      rel: "stylesheet",
      href: "https://unpkg.com/primereact/resources/primereact.min.css",
    },
    {
      rel: "stylesheet",
      href: "https://unpkg.com/primereact/resources/themes/saga-blue/theme.css",
    },
    { rel: "stylesheet", href: stylesheet },
    {
      rel: "stylesheet",
      href: "https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap",
    },
  ];
}

export async function loader(args: LoaderFunction) {
  return json({
    // https://remix.run/docs/en/v1/guides/envvars#browser-environment-variables
    ENV: {
      SENTRY_ENV: requireEnv("SENTRY_ENV"),
      LAUNCH_DARKLY_ENV: requireEnv("LAUNCHDARKLY_CLIENT_KEY"),
      CLARITY_ID: requireEnv("CLARITY_ID"),
      IS_PROD: isProd(),
    },
  });
}

export const meta: MetaFunction = () => {
  return [{ title: "Casi" }];
};

export function Layout({ children }: { children: React.ReactNode }) {
  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body className="font-sans">
        {children}
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export default function App() {
  const data = useLoaderData<typeof loader>();

  return (
    <>
      <Outlet />
      <script
        dangerouslySetInnerHTML={{
          __html: `window.ENV = ${JSON.stringify(data.ENV)}`,
        }}
      />
    </>
  );
}

// function App() {
//   const data = useLoaderData<typeof loader>();
//   const THEME = "LIGHT";

//   // function WrappedOutled() {
//   //   return <Outlet />;
//   // }

//   // const LaunchDarklyWrapper = withLDProvider({
//   //   clientSideID: data.ENV.LAUNCH_DARKLY_ENV,
//   // })(WrappedOutled);

//   return (
//     <html lang="en">
//       <head>
//         <Meta />
//         <Links />
//       </head>
//       <body>
//         <MuiThemeProvider theme={createTheme(THEME)}>
//           <Outlet />
//         </MuiThemeProvider>
//         <script
//           type="text/javascript"
//           dangerouslySetInnerHTML={{
//             __html: `window.ENV = ${JSON.stringify(data.ENV)}`,
//           }}
//         />
//         {data.ENV.IS_PROD && (
//           <script
//             type="application/javascript"
//             dangerouslySetInnerHTML={{
//               __html: `(function(c,l,a,r,i,t,y){
//               c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
//               t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
//               y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
//           })(window, document, "clarity", "script", "${data.ENV.CLARITY_ID}");`,
//             }}
//           />
//         )}
//         <ScrollRestoration />
//         <Scripts />
//       </body>
//     </html>
//   );
// }

// export default withSentry(App);

export function ErrorBoundary() {
  const error = useRouteError();
  console.log("ERROR 123123123j");
  console.log("this is the error", error);

  if (isRouteErrorResponse(error)) {
    if (error.status === 401 || error.status === 403) return <Error401 data={error.data} />;

    if (error.status === 404) return <Error404 data={error.data} />;

    return (
      <div>
        <h1>Oops</h1>
        <p>Status: {error.status}</p>
        <p>{error.data.message}</p>
      </div>
    );
  } else if (error instanceof Error) {
    return (
      <div>
        <h1>Error</h1>
        <p>{error.message}</p>
        <p>The stack trace is:</p>
        <pre>{error.stack}</pre>
      </div>
    );
  } else {
    return <h1>Unknown Error</h1>;
  }
}
