const Error404 = ({ data }: { data?: any }) => {
  return (
    <div>
      <h1>Not found</h1>
      {data && <code>{data}</code>}
    </div>
  );
};

export default Error404;
